const engineMapper = [
	"DOT",
	"LIBLOUIS",
	"AMEDIA",
];

const brailleEngineNumberToText = (engineNumber) => {
	if (typeof engineNumber !== 'number' && typeof engineNumber !== 'string') {
		throw new Error(`유효하지 않은 타입입니다. 숫자나 문자열 형식의 숫자이어야 합니다.`);
	}
	const kind = Number(engineNumber);
	if (isNaN(kind) || kind < 0 || kind >= engineMapper.length) {
		throw new Error(`유효하지 않은 숫자입니다. 0부터 ${engineMapper.length - 1}까지의 값이어야 합니다.`);
	}
	return engineMapper[kind];
};

const brailleEngineTextToNumber = (engineText) => {
	if (typeof engineText !== 'string') {
		throw new Error(`유효하지 않은 타입입니다. 텍스트이어야 합니다.`);
	}
	const index = engineMapper.indexOf(engineText.toUpperCase());
	if (index === -1) {
		throw new Error(`유효하지 않은 텍스트입니다. '${engineMapper.join("', '")}' 중 하나여야 합니다.`);
	}
	return index.toString();
};

export {
	brailleEngineNumberToText,
	brailleEngineTextToNumber,
}